import React from "react"
import { Link } from "gatsby"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"

import Layout from "../components/layout"
import SEO from "../components/seo"

const useStyles = makeStyles(({ spacing }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "100vh",
  },
  header: {
    fontWeight: "bolder",
    paddingBottom: spacing(3),
    maxWidth: 600,
    textAlign: "center",
  },
}))

const NotFoundPage = () => {
  const classes = useStyles()

  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className={classes.root}>
        <Typography color="secondary" variant="h4" className={classes.header}>
          Helaas, de pagina waar u naar zoekt is niet gevonden.
        </Typography>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          component={Link}
          to="/"
        >
          Terug naar de homepagina
        </Button>
      </div>
    </Layout>
  )
}

export default NotFoundPage
